.quill {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.ql-read-only .ql-toolbar svg {
  opacity: 0.5;
}

.ql-toolbar.ql-snow {
  background-color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary-shade);
}

.ql-toolbar .ql-formats svg .ql-stroke {
  stroke: var(--color);
}

.ql-toolbar .ql-formats svg .ql-fill {
  fill: var(--color);
}