#comment-input {
    border-radius: 1em !important;
    padding-left: 1.2em !important;
    padding-right: 1.2em !important;
    border: 1px solid #000;
}

.comments-ion-grid {
    padding: 0;
    --ion-grid-columns: 3
}

.comment-add-new {
    border-bottom: solid 1px black;
}

.comment-listing {
    border-bottom: solid 1px black;
}

.comment-listing-user {
    font-weight: bolder;
}

ion-avatar.remarks-avatar {
    border-radius: 50%;
    border: 1px solid var(--color);
    font-weight: bolder;
    font-size: 2em;
}

ion-avatar.remarks-avatar span {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
}