/* List CSS*/
ion-row.table-list-item-row {
  align-items: center;
}

.list-md.table-list {
  padding-top: 0;
  padding-bottom: 0;
}

/* List Item CSS*/
.list-item::part(native) {
  padding: 15px;
}

ion-item.list-item {
  --inner-padding-end: 0;
}

/* List Item Grid CSS */
.list-item-grid {
  --ion-grid-width: 100%;
}

ion-row.md.table-list-item-row {
  padding: 0;
}

.list-item-grid-col-item::part(native) {
  padding: 0;
  min-height: fit-content;
}

.item ion-label.list-item-field-label {
  font-size: 12px;
  margin: 2px 0px;
  white-space: normal;
  width: 50%;
}

.item ion-label.list-item-field-value {
  text-align: right;
  margin: 2px 0px;
}

.item ion-input.list-item-field-input {
  --padding-top: 0;
  --padding-bottom: 0;
  margin: 2px 0px;
}
