.meter-readings ion-grid {
  --ion-grid-padding: 0px;
}

.meter-readings ion-row.md {
  padding: 5px 0px;
}

.meter-readings ion-item.labeled-inputs {
  --highlight-color-focused: none;
  --show-full-highlight: 0;
}

.meter-readings ion-input.read-only-input {
  background-color: #eee;
  color: #555;
  opacity: 1;
  width: 100%;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.meter-readings ion-input.editable-input {
  opacity: 1;
  width: 100%;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.meter-readings ion-select.editable-select-input {
  opacity: 1;
  width: 100%;
  height: 43px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-indent: 8px;
}

.meter-readings .read-only-input > input {
  margin-left: 8px;
}

.meter-readings .editable-input > input {
  margin-left: 8px;
}

.meter-readings ion-label.read-only-input-label.md {
  transform: scale(0.75) !important;
}

.meter-readings ion-label.editable-input-label.md {
  transform: scale(0.75) !important;
}
