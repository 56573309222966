ion-grid.action-button-grid {
  padding: 16px;
  height: 100%;
  width: 100%;
}

ion-grid.action-button-grid ion-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ion-grid.action-button-grid ion-fab-button {
  border: 1px solid var(--ion-color-contrast);
  border-radius: 50%;
}

ion-grid.action-button-grid ion-label {
  text-align: center;
  font-size: small;
}