ion-textarea.labor-textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  opacity: 1;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

ion-button.labor-comment-view-button {
  font-size: 17px;
  margin: 2px 0px;
  width: 50%;
}
ion-button.labor-comment-view-button::part(native) {
  width: fit-content;
}

.disable-action-sheet-button {
  cursor: not-allowed;
  background-color: gray !important;
  pointer-events: none;
}
