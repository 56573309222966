.inspection-ioncard-content{
  padding: 5px;
}
.inspection-check-label {
  font-size: small;
}

.inspection-helper-note {
  font-size: small;
  text-align: right;
}

.inspection-gridcol-header-input {
  text-align: right;
  background-color: var(--ion-color-medium);
  /* color: var(--ion-text-color); */
  color: white;
}

.inspection-gridcol-header {
  background-color: var(--ion-color-medium);
  /* color: var(--ion-text-color); */
  color: white;
}

.inspection-gridcol-inputcol {
  text-align: right;
  width: 100%;
}

.inspection-gridcol-inputcol-fail {
  text-align: right;
  width: 100%;
  background-color: var(--ion-color-warning);
}

.inspection-ion-select {
  width: 100%;
  text-align: right;
}

.inspection-ion-select-fail {
  width: 100%;
  text-align: right;
  background-color: var(--ion-color-warning);
}