/* Resource Item Grid CSS */
.resource-item-grid-col-title-item::part(native) {
  padding: 0;
  min-height: calc(var(--min-height) / 1.5);
}

/* Resource Item Title CSS */
.resource-item-title {
  text-align: left;
  padding-left: 0;
  font-size: 18px;
  font-weight: 600;
}
