ion-textarea.approval-textarea {
    padding-inline-start: 15px;
}

ion-card-title.card-title.ios {
    font-size: 21px;
}

ion-grid.card-details {
    padding-inline-start: 20px;
}

ion-grid.card-details.ios {
    padding-top: 1px;
    padding-bottom: 1px;
}

ion-card-content.card-description {
    padding-inline-start: 20px;
    font-weight: 500;
}

ion-grid.comment-title {
    padding-inline-start: 20px;
}

ion-grid.comment-title.ios {
    padding-top: 0px;
    padding-bottom: 0px;
}

ion-button.log {
    padding-inline-start: 40px;
}