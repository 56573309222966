ion-title.tab-page-title {
    text-align: center;
    padding-inline: 0px;
    padding: 0px;
    z-index: 1;
}

ion-button.tab-page-back-button {
    --padding-start: 0;
    --padding-end: 0;
    --color: unset;
    z-index: 2;
}