ion-input.wo-editable-input, ion-select.wo-editable-input {
  opacity: 1;
  width: 100%;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
ion-textarea.wo-editable-input {
  opacity: 1;
  width: 100%;
  min-height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

ion-select.wo-editable-input {
  height: 36px;
  padding: 0px;
}

ion-select.wo-editable-input.md::part(icon) {
  transform: none;
}

ion-select.wo-editable-input.ios::part(icon) {
  left: -5px;
}

.item-has-focus.item-label-stacked ion-select.wo-editable-input.md::part(icon) {
  transform: rotate(180deg);
}

.wo-editable-input>input {
  margin-left: 8px;
}
.wo-editable-input textarea.native-textarea {
  margin-left: 8px;
}


ion-select.wo-editable-input::part(text) {
  margin-left: 8px;
}

.wo-list-item.md::part(native) {
  padding: 10px 5px;
}

.wo-list-item::part(native) {
  padding: 5px;
}

ion-item.wo-list-item {
  --inner-padding-end: 0;
}

ion-label.wo-editable-input-label.md {
  transform: scale(0.75) !important;
}

ion-note.quick-wo-error-note {
  color: var(--ion-color-danger);
}

.quick-wo-title {
  font-weight: 500;
}